import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfigModule, I18nConfig, I18nModule, OccConfig, provideConfig } from '@spartacus/core';
import { FormErrorsModule, GlobalMessageComponentModule } from '@spartacus/storefront';
import { userAccountTranslationChunksConfig, userAccountTranslations } from '@spartacus/user/account/assets';
import { LoginFormComponentService } from '@spartacus/user/account/components';
import { ForgotPasswordComponentService } from '@spartacus/user/profile/components';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { SlaonThreeKitLoginPopupComponent } from './slaon-three-kit-login-popup/slaon-three-kit-login-popup.component';
import { SloanLoginComponent } from './sloan-login/sloan-login.component';
import { SloanSignupComponent } from './sloan-signup/sloan-signup.component';
import { environment } from 'src/environments/environment';
import { SloanForgotPasswordComponent } from '../../cpq-profile/components/sloan-forgot-password/sloan-forgot-password.component';
import { SloanLoginReacceptanceComponent } from './sloan-reacceptance/sloan-login-reacceptance/sloan-login-reacceptance.component';

export const sloanCpqForGorPasswordEndpointsConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        // TODO : Commented by Pushpak
        //    userForgotPassword: '/cpqUsers/cpqforgottenpasswordtokens',
      },
    },
  },
};

@NgModule({
  declarations: [
    SloanLoginComponent,
    SloanSignupComponent,
    SloanForgotPasswordComponent,
    SlaonThreeKitLoginPopupComponent,
    SloanLoginReacceptanceComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    FormErrorsModule,
    GlobalMessageComponentModule,
    NgSelectModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    ConfigModule.withConfig(sloanCpqForGorPasswordEndpointsConfig),
  ],
  exports: [SloanLoginComponent, SloanSignupComponent, SloanForgotPasswordComponent, ReactiveFormsModule],
  providers: [
    LoginFormComponentService,
    ForgotPasswordComponentService,
    provideConfig({
      i18n: {
        resources: userAccountTranslations,
        chunks: userAccountTranslationChunksConfig,
      },
    } as I18nConfig),
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptchaSiteKey,
      } as RecaptchaSettings,
    },
  ],
})
export class SloanCpqAccountComponentModule {}
