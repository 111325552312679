<div class="modal-content rounded-0 sign-up">
    <div class="modal-header">
    </div>
   
    <div class="modal-body nopad">
      <form [formGroup]="reacceptanceForm" (ngSubmit)="onSubmit()" novalidate>
          <div class="col-12 mt-3 float-left">
            <ng-container *ngIf="termsofuse==false && Privacypolicy==true">
              <ul>
                <li> Sloan Valve Company has recently updated our Terms of Use Policy. To access your account please review
                  and agree to the current <a href="'{{this.sloantermsofuse}}'" data-ckey="sloan-terms-of-use"
                    target="_blank" rel="noopener" class="ps-contract-link">Terms of Use</a>.</li>
              </ul>
            </ng-container>
            <ng-container *ngIf="termsofuse==true && Privacypolicy==false">
              <ul>
                <li> Sloan Valve Company has recently updated our Privacy Policy. To access your account please review and
                  agree to the current <a href={{this.sloanprivacypolicy}} data-ckey="sloan-terms-of-use" target="_blank"
                    rel="noopener" class="ps-contract-link">Privacy Policy</a>.</li>
              </ul>
            </ng-container>
            <ng-container *ngIf="termsofuse==false && Privacypolicy==false">
              <ul>
                <li> Sloan Valve Company has recently updated our Terms of Use Policy. To access your account please review
                  and agree to the current <a href='{{this.sloantermsofuse}}' data-ckey="sloan-terms-of-use" target="_blank"
                    rel="noopener" class="ps-contract-link">Terms of Use</a>.</li>
                <li> Sloan Valve Company has recently updated our Privacy Policy. To access your account please review and
                  agree to the current <a href={{this.sloanprivacypolicy}} data-ckey="sloan-terms-of-use" target="_blank"
                    rel="noopener" class="ps-contract-link">Privacy Policy</a>.</li>
              </ul>
            </ng-container>
           <!-- <div class="form-check form-check-consent form-check-inline mb-2" id="">
             <input class="form-check-input" type="checkbox" id="clickwrapContainer" formControlName="acceptanceCheckbox" />
              <label class="form-check-label mb-2" for="clickwrapContainer">
                <span class="text-danger mandatory-font-size ml-1">*</span>
                I understand and agree to <a href="{{sloanprivacypolicy}}" data-ckey="sloan-terms-of-use" target="_blank" rel="noopener" class="ps-contract-link">Privacy Policy</a> and <a href="{{sloantermsofuse}}" data-ckey="sloan-terms-of-use" target="_blank" rel="noopener" class="ps-contract-link">Terms of Use</a>
              </label>
            </div>-->
        </div>
          <div class="modal-footer col-12 pt-4 mt-3 float-left">
            <button type="submit" class="btn btn-primary float-right">
            {{ 'sloanloginForm.agree' | cxTranslate }}
          </button>
          </div>
      </form>
  </div>
</div>