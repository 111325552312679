// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://api-stg.sloan.com',
  prefix: '/occ/v2/',
  baseSite: ['sloancpq'],
  ORIGIN: '*.sloan.com:443',
  threeKitToken: '290cf046-5c07-4ad6-b912-a1ec83d5b24e',
  threeKitUrl: 'https://sloan-staging.herokuapp.com',
  templateId: '101',
  globalHelpphoneNumber: '800.982.5839',
  globalHelpemail: 'designersinks@sloan.com',
  globalHelpworkingDays: 'Monday through Friday',
  globalHelpworkingHours: '7:00AM - 5:00PM(Central Time)',
  gtmId: 'GTM-TS6V67S',
  recaptchaSiteKey: '6LctkUYoAAAAAJxBbMrFTEuvffcTTDSOws3wyXTQ',
  siteAccessId: '2e7b89cf-1694-41ab-b7d5-0993bd2d100f', // A Clickwrap Site Access ID
  groupKey: 'sloan-registration',
  sloantermsofuse:'https://legal.sloan.com/legal.html#terms-of-use',
  sloanprivacypolicy:'https://legal.sloan.com/legal.html#privacy-policy',
  Privacypolicy:'564207',
  termsofuse:'562035'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
