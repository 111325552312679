import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, take } from 'rxjs';
import { SloanCpqUserService } from '../../services/sloan-cpq-user.service';
import { SLOAN_CPQ_USER_KEY } from 'src/app/constants/storage.constant';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { SloanLoginReacceptanceComponent } from 'src/feature-libs/cpq-user/cpq-account/components/sloan-reacceptance/sloan-login-reacceptance/sloan-login-reacceptance.component';

@Component({
  selector: 'app-sloancpq-outlet',
  templateUrl: './sloancpq-outlet.component.html',
  styleUrls: ['./sloancpq-outlet.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SloancpqOutletComponent implements OnInit, AfterViewChecked {
  showGlobalMessage: Observable<boolean> = this.sloanCpqUserService.canShowGlobalMessage();
  sloantermsofuse = environment.sloantermsofuse;
  sloanprivacypolicy = environment.sloanprivacypolicy;
  SiteAccessId=environment.siteAccessId;
  GroupKey=environment.groupKey;
  email:string;
  reacceptancemodalRef;
  //companyName:string;
  constructor(
    private sloanCpqUserService: SloanCpqUserService, 
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal
  ) {
     this.sloanCpqUserService.getCPQUserData();
    this.sloanCpqUserService.loadUserData().pipe(take(2)).subscribe((res) => {
      let email = res?.uid;
      if (res?.uid.includes(SLOAN_CPQ_USER_KEY)) {
        email = res?.uid.split(SLOAN_CPQ_USER_KEY)[1];
      }
      if(email){
        this.sloanCpqUserService.checkPactSafeAPI(this.SiteAccessId, this.GroupKey, email)
        this.sloanCpqUserService.checkpactsafedata$.subscribe(res => {
          if (res[environment.termsofuse] == false || res[environment.Privacypolicy] == false) {
            let ngbModalOptions: NgbModalOptions = {
              backdrop: 'static',
              keyboard: false
            };
            if (!this.modalService.hasOpenModals()) {
              this.reacceptancemodalRef = this.modalService.open(SloanLoginReacceptanceComponent, ngbModalOptions);
            };
          }
        })
      }
    });
  }
  ngOnInit(): void {}

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
}
